<template>
    <div class="faq">
        <Header />
        <div class="banner">
            <div class="container">
                <div class="title">HOW TO SELL YOUR CAR A COMPLETE GUIDE</div>
            </div>
        </div>
        <div class="main">

            <div class="container">

                <div class="warpper">

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h1>HOW TO SELL YOUR CAR A COMPLETE GUIDE</h1>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>Selling your car at the desired worth is not everyone's strong suit. Multiple factors play their role in the successful dealing of the automotive business. Here are the top five points that one needs to consider while selling their car.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>•REJUVENATE YOUR MINDSET.</h3>
                            <p>The foremost aspect that should be followed is to refresh your mind and get into the nitty-gritty of your car. Take a good understanding of details like</p>
                            <p>°Market value of your vehicle.</p>
                            <p>°Secure registration and documents of the vehicle</p>
                            <p>°Check for the damages it might have.</p>
                            <p>°Make up your mind for the obvious questions from the buyer.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>• DECIDE WHO YOU WANT TO SELL THE CAR</h3>
                            <p>The secret to selling the car lies in dealing with the buyer. Trading your car at the dealership has its pros and cons. Dealerships usually don’t offer a hefty amount to a private seller. They keep the cut to refurbish the car and fix the damages. It may have to be sold out later at a much more reasonable price. However, it has the advantage of a quick buying service and can cover the cost you would have to spend on fixing the scratches and minor repairs otherwise.</p>
                            <p>On the other hand, selling your car privately can be a time taking exercise. One would need to keep the car up to date. Before unravelling it to the customer, it must be thoroughly washed and cleaned. Negotiating and communicating your demand respectfully is the essence of selling your vehicle at its desired worth. It might be strenuous at times, but who doesn't like big profit when it only takes your communications skills to spill the magic.</p>
                        </div>
                    </div>

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>•ESTABLISH YOUR CAR PROFILE </h3>
                            <p>Long gone are the days when you had to run the selling business based on personal contacts. Take your car out of the garage on a bright sunny day, get rid of the clutter and offensive atmosphere of your car because nobody wants to experience your smelly rugby stuff. Wash it up and rub some compound over to give a shiny outlook. Click some cinematic photos of your car and post them on the popular online car selling companies. Keep your car profile concise, and don't forget to add a vehicle history report. Put the best version of your car in front of the global buying car industry to secure an excellent value for your car.</p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>NEGOTIATE AND COMMUNICATE YOUR DEMAND</h3>
                            <p>It is ideal to set a particular value for your car from the beginning of the negotiation. Next up is your ability to justify that demand by giving an apt amount of details and answering the customer's queries regarding the value of your car. Get in line with the registration, proof of ownership, roadworthy certificate, owner's manual, servicing logbook and supplements, extra receipts for labour and parts and transfer of ownership form. </p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>FINALIZE THE DEAL AND INVOLVE LOCAL GOVERNMENT</h3>
                            <p>Once the deal is finalised, it is advisable to opt for bank draft or cash as a mode of payment because online transfers and cheques are vulnerable to fraud. All over the globe, each country or state has a separate regulation for transferring the ownership, so it is good to involve the officials after your deal is finalised privately. If you have any doubts about the taxation, bills, Drive clean test, and safety certificates, don't hesitate to <router-link  to="/">contact your state's local body</router-link> and make sure you and your customer have met the required criteria to proceed with this deal.</p>

                        </div>
                    </div>
                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
};
</script>
  
<style scoped lang="less">
.ddRow {
    font-family: Ubuntu-Regular, Ubuntu;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 1%;
    margin-bottom: 36px;
    width: 100%;
}

.ddRow h2,
.ddRow h1,
.ddRow h3 {
    font-weight: bold;
    color: #004AAD;
    margin: 4% 0 2% 0;
    text-align: center;
}

.ddRow ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 2%;
    margin-left: 4%;
}

.ddRow img {
    width: 100%;
}

.ddCol-12 {
    width: 100%;
}

.ddCol-6 {
    width: 50%;
    padding: 2%;
    float: left;
}

.ddRow:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-device-width: 960px) {
    .ddRow {
        display: block;
    }

    .ddCol-6 {
        width: 100%;
    }
}

.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  